import React from 'react'

export default function SkillItemModal() {
  return (
    <>
    <form action="" method="post">
            <div className="flex flex-wrap w-full">
                <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3 mt-3">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Icon
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue="uil uil-brackets-curly"
                        name="skill_icon"
                    />
                    </div>
                </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Title
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue="Frontend Developer"
                        name="skill_title"
                    />
                </div> 
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        Description <small className='text-gray-400'>Small description</small>
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue="More Than 3 years"
                        name="skill_description"
                    />
                </div>
              </div>
            </div>
          </form>
    </>
  )
}

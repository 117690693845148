import FeedbackTable from 'components/Tables/FeedbackTable'
import React from 'react'

export default function Feedback() {
  return (
    <>
        <FeedbackTable />
    </>
  )
}

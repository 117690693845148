import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// components

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import HeaderStats from 'components/Headers/HeaderStats.js';
// import FooterAdmin from 'components/Footers/FooterAdmin.js';

// views
import Home from 'views/sections/Home';
import About from 'views/sections/About';
import Skills from 'views/sections/Skills';
import { ModalsContainer } from 'components/Modals/ModalsContainer';
import Qualifications from 'views/sections/Qualifications';
import Services from 'views/sections/Services';
import Projects from 'views/sections/Projects';
import Offer from 'views/sections/Offer';
import Contact from 'views/sections/Contact';

export default function SectionsLayout() {
  return (
    <>
      {/* display when modal tregged */}
      <ModalsContainer />

      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/section/home" exact component={Home} />
            <Route path="/section/about" exact component={About} />
            <Route path="/section/skills" exact component={Skills} />
            <Route path="/section/qualifications" exact component={Qualifications} />
            <Route path="/section/services" exact component={Services} />
            <Route path="/section/projects" exact component={Projects} />
            <Route path="/section/offer" exact component={Offer} />
            <Route path="/section/contact" exact component={Contact} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}

import GeneralSettingsForm from 'components/Forms/GeneralSettingsForm'
import React from 'react'

export default function GeneralSettings() {
  return (
    <>
        <GeneralSettingsForm />
    </>
  )
}

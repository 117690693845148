import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, baseQuery } from '../config';

// Define a service using a base URL and expected endpoints
export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set(
        'authorization',
        `Bearer 6|4DPWovjjgOKd16xiJ2omhMF2cbwXOJsPEIsOLc6g`,
      );

      return headers;
    },
  }),
  endpoints: (builder) => ({
    addNewProject: builder.mutation({
      query: (body) => ({
        url: `v1/projects`,
        method: 'POST',
        body,
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      }),
    }),
  }),
});

export const { useAddNewProjectMutation } = projectsApi;

import SocialMediaForm from 'components/Forms/SocialMediaForm'
import React from 'react'

export default function SocialMedia() {
  return (
    <>
        <SocialMediaForm />
    </>
  )
}

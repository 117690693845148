import React, { useState, useEffect } from 'react';
import { useAddNewProjectMutation } from '../../../API/EndPointsSlices/Projects';
import { Success } from '../../Alerts/Alerts';
import { useFormik } from 'formik';
import { schema } from './Controller.jsx'; // yup validation schema

export default function ProjectModal() {
  let [projectImage, setProjectImage] = useState('');
  let [projectTitle, setProjectTitle] = useState('');
  let [projectLink, setProjectLink] = useState('');
  let [projectDescription, setProjectDescription] = useState('');

  const [addForm, result] = useAddNewProjectMutation(); // RTK Query to post data
  let [errors, setErrors] = useState({}); // API Errors
  let [message, setMessag] = useState(null);

  let submitHandler = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('project_image', projectImage);
    formData.append('project_title', projectTitle);
    formData.append('project_link', projectLink);
    formData.append('project_description', projectDescription);

    addForm(formData);
  };
  useEffect(() => {
    if (result?.data?.errors) {
      setErrors({});
      for (let i in result.data.errors) {
        setErrors({ [i]: result.data.errors[i][0] });
      }
    }

    if (result?.data?.success === true) {
      setMessag(result.data.message ?? 'Check API Logger !');
    }

    // clean up
    return () => {
      return 0;
    };
  }, [result?.data]);

  return (
    <>
      {message ?? null}
      <form
        className="mx-auto pt-3"
        encType="multipart/form-data"
        method="POST"
        onSubmit={submitHandler}>
        <div className="flex flex-wrap" style={{ maxWidth: '768px' }}>
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="project_image">
                Image
              </label>
              <input
                id="project_image"
                type="file"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="project_image"
                onChange={(e) => {
                  console.log(e.target.files[0].size);
                  setProjectImage(e.target.files[0]);
                }}
                required
              />
              {errors?.project_image ? (
                <small style={{ color: 'crimson' }}>
                  {errors.project_image}
                </small>
              ) : null}
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="project_title">
                Title
              </label>
              <input
                id="project_title"
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="project_title"
                placeholder={'ex: Demo ecommerce app'}
                value={projectTitle}
                onChange={(e) => setProjectTitle(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="project_link">
                Project Link
              </label>
              <input
                id="project_link"
                type="text"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="project_link"
                placeholder={'ex: https://kat-porfolio.netilfy.app'}
                value={projectLink}
                onChange={(e) => setProjectLink(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="project_description">
                Project Description
              </label>
              <textarea
                id="project_description"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                name="project_description"
                placeholder={
                  'ex: A small demo website about E-store using SveltKit'
                }
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                // onChange={formik.handleChange}
                // value={formik.values.project_description}
                // onBlur={formik.handleBlur}
                required></textarea>
              {/* {formik.touched.project_description &&
              formik.errors.project_description ? (
                <small style={{ color: 'crimson' }}>
                  {formik.errors.project_description}
                </small>
              ) : null} */}
            </div>
          </div>
          <div className="w-full p-4">
            <button
              className="w-full bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="submit">
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

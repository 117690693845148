import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  modalName: null,
  show:false,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
      runModal: (state, action) => {
        state.modalName = action.payload
        state.show = !state.show
      },
      toggleShow:(state) => {
          state.show = !state.show
      },
      setModalShow:(state,action) => {
        state.show = action.payload
    }
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { runModal,toggleShow,setModalShow } = modalSlice.actions
  
  export default modalSlice.reducer
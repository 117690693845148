import { SkillsForm } from 'components/Forms/Sections/SkillsForm'
import React from 'react'

export default function Skills() {
  return (
    <>
        <SkillsForm />
    </>
  )
}

import React from 'react';
import { ProjectsForm } from 'components/Forms/Sections/ProjectsForm';

export default function Projects() {
  return (
    <>
      <ProjectsForm />
    </>
  );
}

/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

import NotificationDropdown from 'components/Dropdowns/NotificationDropdown.js';
import UserDropdown from 'components/Dropdowns/UserDropdown.js';

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/">
            Notus React
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/">
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            {/* <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form> */}

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Admin Layout Pages
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/dashboard') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/admin/dashboard">
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/dashboard') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Dashboard
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/settings') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/admin/settings">
                  <i
                    className={
                      'fas fa-tools mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/settings') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Settings
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/tables') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/admin/tables">
                  <i
                    className={
                      'fas fa-table mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/tables') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Tables
                </Link>
              </li> */}

              {/* <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/maps') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/admin/maps">
                  <i
                    className={
                      'fas fa-map-marked mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/maps') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Maps
                </Link>
              </li> */}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              App Settings
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/settings/logo">
                  <i className="fas fa-circle text-blueGray-400 mr-2 text-sm"></i>{' '}
                  Logo
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/settings/socialmedia">
                  <i className="fas fa-hashtag text-blueGray-400 mr-2 text-sm"></i>{' '}
                  Social-Media
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/settings/seo">
                  <i className="fas fa-search text-blueGray-400 mr-2 text-sm"></i>{' '}
                  SEO
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/settings/cv">
                  <i className="fas fa-file text-blueGray-400 mr-2 text-sm"></i>{' '}
                  Cv
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/settings/general">
                  <i className="fas fa-cog text-blueGray-400 mr-2 text-sm"></i>{' '}
                  General
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Portfolio sections
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/Home') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/home">
                  <i
                    className={
                      'fas fa-home mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/Home') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Home
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/about') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/about">
                  <i
                    className={
                      'fas fa-address-card mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/about') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  About
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/skills') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/skills">
                  <i
                    className={
                      'fas fa-poll mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/skills') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Skills
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/qualifications') !==
                    -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/qualifications">
                  <i
                    className={
                      'fas fa-user-tie mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/section/qualifications',
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Qualifications
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/services') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/services">
                  <i
                    className={
                      'fas fa-user-cog mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/services') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Services
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/projects') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/projects">
                  <i
                    className={
                      'fas fa-folder mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/projects') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Projects
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/offer') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/offer">
                  <i
                    className={
                      'fas fa-hand-holding-usd mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/offer') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Offer
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/section/contact') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/section/contact">
                  <i
                    className={
                      'fas fa-address-book mr-2 text-sm ' +
                      (window.location.href.indexOf('/section/contact') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Contact
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Reports sections
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/reports/feedback') !== -1
                      ? 'text-lightBlue-500 hover:text-lightBlue-600'
                      : 'text-blueGray-700 hover:text-blueGray-500')
                  }
                  to="/reports/feedback">
                  <i
                    className={
                      'fas fa-home mr-2 text-sm ' +
                      (window.location.href.indexOf('/reports/feedback') !== -1
                        ? 'opacity-75'
                        : 'text-blueGray-300')
                    }></i>{' '}
                  Feedback
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

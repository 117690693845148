import { QualificationsForm } from 'components/Forms/Sections/QualificationsForm'
import React from 'react'

export default function Qualifications() {
  return (
    <>
    <QualificationsForm />
    </>
  )
}

import { ServicesForm } from 'components/Forms/Sections/ServicesForm'
import React from 'react'

export default function Services() {
  return (
    <>
        <ServicesForm />
    </>
  )
}

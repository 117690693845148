import React from 'react';
import RegularModal from './RegularModal';
import SkillListModal from './Skills/SkillListModal';
import SkillItemModal from './Skills/SkillItemModal';
import EducationModal from './Qualifications/EducationModal';
import JobModal from './Qualifications/JobModal';
import ServiceModal from './Services/ServiceModal';
import ServiceDetailModal from './Services/ServiceDetailModal';
import ProjectModal from './Projects/ProjectModal';
import { useSelector } from 'react-redux';

export let ModalsContainer = () => {
  const modal = useSelector((state) => state.modal);

  switch (modal.modalName) {
    case 'skillListModal':
      return (
        <RegularModal
          modalTitle="Skill List"
          formComponent={<SkillListModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'skillItemModal':
      return (
        <RegularModal
          modalTitle="Skill"
          formComponent={<SkillItemModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'educationModal':
      return (
        <RegularModal
          modalTitle="Educations"
          formComponent={<EducationModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'jobModal':
      return (
        <RegularModal
          modalTitle="Jobs"
          formComponent={<JobModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'serviceModal':
      return (
        <RegularModal
          modalTitle="Services"
          formComponent={<ServiceModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'serviceDetailsModal':
      return (
        <RegularModal
          modalTitle="Service Details"
          formComponent={<ServiceDetailModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    case 'projectModal':
      return (
        <RegularModal
          modalTitle="Projects"
          formComponent={<ProjectModal />}
          exitAndSaveButtons={false}
          showModal={modal.show}
        />
      );
    default:
      return <></>;
  }
};

import { string, email, object, mixed } from 'yup';

const re =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export let schema = object().shape({
  project_image: mixed().required('Please select image'),
  project_title: string('Title should be strings').required(
    'Please write Title',
  ),
  project_link: string('Link should be in string')
    .matches(re, 'Link is invalid')
    .required('Link is Required'),
  project_description: string('Description should be in string form')
    .max(200, 'Description is too Long only 200 characters or less')
    .required('Please type a Description'),
});

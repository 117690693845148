import LogoForm from 'components/Forms/LogoForm'
import React from 'react'

export default function Logo() {
  return (
    <>
        <LogoForm />
    </>
  )
}

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const BASE_URL = 'https://portfolio-api.kat.sd/api/';
// export const BASE_URL = 'http://127.0.0.1:8000/api/';

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    headers.set(
      'authorization',
      `Bearer 6|4DPWovjjgOKd16xiJ2omhMF2cbwXOJsPEIsOLc6g`,
    );

    return headers;
  },
});

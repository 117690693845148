import ContactForm from 'components/Forms/Sections/ContactForm'
import React from 'react'

export default function Contact() {
  return (
    <>
       <ContactForm />
    </>
  )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalName: null,
  show: false,
};

export const projectController = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addProject: (state, action) => {},
  },
});

// Action creators are generated for each case reducer function
export const { addProject } = projectController.actions;

export default projectController.reducer;

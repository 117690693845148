import OfferForm from 'components/Forms/Sections/OfferForm'
import React from 'react'

export default function Offer() {
  return (
    <>
    <OfferForm />
    </>
  )
}

import SeoForm from 'components/Forms/SeoForm'
import React from 'react'

export default function Seo() {
  return (
    <>
    <SeoForm />
    </>
  )
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleShow, setModalShow } from 'features/modals/modalSlice';

export default function RegularModal(props) {
  let [showModal, setShowModal] = React.useState(false);
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.showModal === true) {
      setShowModal(props.showModal);
    }

    if (props.showModal === false) {
      setShowModal(props.showModal);
    }

    return () => {
      return null;
    };
  }, [modal.show]);

  let localChange = () => {
    // hide modal after submit
    setShowModal(false);
    dispatch(setModalShow(false));
  };

  return (
    <div className="">
      {showModal ? (
        <div className="flex justify-center">
          <div
            className="w-full h-full md:w-1/2 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
            // onClick={() => localChange()}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.modalTitle ?? 'no title'}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => localChange()}>
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {/* appended component */}
                  {props.formComponent}
                </div>
                {/*footer*/}
                {props.exitAndSaveButtons ? (
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => localChange()}>
                      Close
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => localChange()}>
                      Save Changes
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </div>
  );
}

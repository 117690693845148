import AboutForm from 'components/Forms/Sections/AboutForm'
import React from 'react'

export default function About() {
  return (
    <>
        <AboutForm />
    </>
  )
}

import { configureStore } from '@reduxjs/toolkit';
import modalSlice from '../features/modals/modalSlice';
import projectController from '../features/projects/projectController';
import { projectsApi } from '../API/EndPointsSlices/Projects';

export const store = configureStore({
  reducer: {
    modal: modalSlice,
    portfolio: projectController,
    [projectsApi.reducerPath]: projectsApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(projectsApi.middleware),
});

import React from 'react'

export default function ServiceDetailModal() {
  return (
    <>
    <form className='mx-auto'>
      <div className="flex flex-wrap" style={{maxWidth:'768px'}}>
        <div className="w-full px-4">
          <div className="relative w-full mb-3 mt-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Service
                  </label>
                  <select className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue=""
                    name="service_id">
                    <option value="">Service 1</option>
                    <option value="">Service 2</option>
                    <option value="">Service 3</option>
                  </select>
          </div>
        </div>

        <div className="w-full px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password">
              Service Name
            </label>
            <textarea
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              defaultValue="Service Description"
              name="service_description"
            ></textarea>
          </div>
        </div>

      </div>
    </form>
</>
  )
}

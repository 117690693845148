import HomeForm from 'components/Forms/Sections/HomeForm'
import React from 'react'

export default function Home() {
  return (
    <>
        <HomeForm />
    </>
  )
}

import CvForm from 'components/Forms/CvForm'
import React from 'react'

export default function Cv() {
  return (
    <>
        <CvForm />
    </>
  )
}

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// components

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import HeaderStats from 'components/Headers/HeaderStats.js';
// import FooterAdmin from 'components/Footers/FooterAdmin.js';

// views
import Dashboard from 'views/admin/Dashboard.js';
import Maps from 'views/admin/Maps.js';
import Settings from 'views/admin/Settings.js';
import Tables from 'views/admin/Tables.js';
import Logo from 'views/settings/Logo';
import SocialMedia from 'views/settings/SocialMedia';
import Seo from 'views/settings/Seo';
import Cv from 'views/settings/Cv';
import GeneralSettings from 'views/settings/GeneralSettings';

export default function SettingLayout() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/settings/logo" exact component={Logo} />
            <Route path="/settings/socialmedia" exact component={SocialMedia} />
            <Route path="/settings/seo" exact component={Seo} />
            <Route path="/settings/cv" exact component={Cv} />
            <Route path="/settings/general" exact component={GeneralSettings} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* <FooterAdmin /> */}
        </div>
      </div>
    </>
  );
}
